import React from 'react';
import styles from '../documents.module.scss'
import useTheme from '../../../hooks/useTheme';
const UserDocument = () => {
    const gTheme = useTheme()
    return (
        <div className={"pageTop"}>
            <div className="wrapper">
                <div className="emptyBlock f-column gap-40">
                    <div className={`sectionTitle ${gTheme("lt-coal-c", "dk-gray-c")}`}>
                        Пользовательское соглашение
                    </div>
                    <p className={`${styles.text} ${gTheme("lt-c", "dk-c")}`}>
                        <br/>Термины
                        <br/>Соглашение — предложение Компании использовать Сайт и Мобильное приложение на изложенных в данном документе условиях.
                        <br/>Компания — ООО "ГМ ГРУПП" (ИНН 8606018000, ОГРН 1228600002025), находится по адресу: 628405, Ханты-Мансийский автономный округ - Югра, г.о. Сургут, г Сургут, пр-кт Комсомольский, д. 19.
                        <br/>Клиент — любое лицо, желающее ознакомиться с Сайтом, Мобильным приложением, и/или использовать их Сервисы, и/или имеющее Личный кабинет.
                        <br/>Сайт — сайт Компании по адресу guli-guli.ru. Сайт включает мобильную и десктопную версию.
                        <br/>Сервисы — функционал Сайта, Мобильного приложения.
                        <br/>Контент — все объекты, размещённые Компанией на Сайте, в Мобильном приложении, в том числе элементы дизайна, текст, графические изображения, иллюстрации, фотографии, видео и иные охраняемые законом материалы.
                        <br/>Личный кабинет — учётная запись Клиента на Сайте, в Мобильном приложении, которая содержит персональные данные Клиента, а также иную информацию, связанную с использованием Сайта, Мобильного приложения.
                        <br/>В Соглашении также используются термины и их определения, указанные в Публичной оферте о продаже товаров дистанционным способом.
                        <br/>1. Общие положения и предмет Соглашения
                        <br/>1.1. Соглашение является публичной офертой, то есть предложением заключить договор на указанных в нём условиях неопределенному кругу лиц (ст. 437 ГК РФ).
                        <br/>1.2. Предмет Соглашения — предоставление Клиенту доступа к использованию Сайта, Мобильного приложения и их Сервисов на условиях, изложенных в Соглашении.
                        <br/>1.3. Использование Сервисов Сайта и Мобильного регулируют:
                        <br/>1.3.1. Соглашение;
                        <br/>1.3.2. Политика обработки персональных данных.
                        <br/>1.4. Клиент обязан ознакомиться с условиями Соглашения, а также с Политикой обработки персональных данных до момента регистрации на Сайте и/или в Мобильном приложении, а также до оформления заказа через Киоск непосредственно в Пиццерии.
                        <br/>1.5. Клиент принимает условия Соглашения с момента регистрации Личного кабинета в соответствии с разделом 2 Соглашения.
                        <br/>1.6. В случае несогласия с условиями Соглашения Клиент не вправе регистрироваться на Сайте и в Мобильном приложении и использовать их Сервисы.
                        <br/>1.7. Компания не продаёт Товары, описание которых указано на Сайте, в Мобильном приложении. Компания даёт возможность Клиенту ознакомиться с описанием товара и информацией о Пиццериях на Сайте и в Мобильном приложении. Компания передает заказы Клиентов Пиццериям, которые выполняют заказы на покупку Товаров.
                        <br/>1.8. Отношения Клиентов с Пиццериями по покупке Товаров и их качеству регулирует Публичная оферта о продаже товаров дистанционным способом.
                        <br/>1.9. Положения Пользовательского соглашения, регулирующие недоступные (ограниченные) для Клиента функции Сайта, Мобильного приложения, не применяются до тех пор, пока Пользователю такие функции не станут доступны в явном виде.
                        <br/>1.10. Информация о Товарах, доставка которых осуществляется Курьерской службой, может отмечаться на Сервисе иконкой с изображением человека на скейтборде.
                        <br/>2. Порядок регистрации и удаления Личного кабинета
                        <br/>Регистрация
                        <br/>2.1. Чтобы самостоятельно зарегистрировать Личный кабинет на Сайте или в Мобильном приложении Клиенту следует:
                        <br/>2.1.1. Ввести номер телефона в федеральном формате (+7 ХХХ ХХХ ХХ ХХ). Указанный Клиентом номер телефона является логином для входа в Личный кабинет;
                        <br/>2.1.2. Ввести код, отправленный Клиенту в виде SMS-сообщения на указанный им номер телефона. Данный код является паролем к Личному кабинету Клиента.
                        <br/>2.2. Регистрация считается завершенной после корректного ввода Клиентом кода, полученного им в SMS–сообщении.
                        <br/>2.3. При регистрации Клиент обязан использовать только номер телефона, выделенный ему оператором связи и зарегистрированный в установленном порядке на Клиента.
                        <br/>2.4. Личный кабинет Клиента создаётся автоматически в следующих случаях:
                        <br/>2.4.1. При заказе по Телефону;
                        <br/>2.4.2. При заказе в Пиццерии: на кассе или через Киоск, если Клиент указал номер телефона при оформлении заказа Товаров.
                        <br/>Имя
                        <br/>2.5. Клиент вправе указать имя (в том числе псевдоним) в Личном кабинете.
                        <br/>2.6. В качестве имени запрещено указывать слова и наименования, использование которых ограничено законодательством, а также нарушает права третьих лиц, в том числе, но не ограничиваясь: нецензурную лексику, призывы к терроризму, разжиганию вражды.
                        <br/>2.7. В случае нарушения условий, указанных в п. 2.6, Пиццерия вправе отменить заказ Клиента в одностороннем порядке и (или) реализовать свое право на самозащиту.
                        <br/>Дата рождения
                        <br/>2.8. Клиент вправе указать день и месяц рождения в Личном кабинете.
                        <br/>2.9. Клиент не вправе самостоятельно менять день и месяц рождения. Чтобы изменить день и месяц рождения, Клиенту следует направить такую просьбу Компании через Чат или по Телефону.
                        <br/>2.10. Изменение даты и месяца рождения допускается не чаще 1 раза в течение 6 месяцев.
                        <br/>Адрес электронной почты и рассылка
                        <br/>2.11. Клиент вправе указать адрес электронной почты в Личном кабинете.
                        <br/>2.12. Клиент вправе подписаться на рассылку. Подписка на рассылку означает, что Клиент согласен на получение от Компании рекламных предложений через SMS-сообщения, push-уведомления, email и другое.
                        <br/>Удаление Личного кабинета
                        <br/>2.13. Клиент вправе удалить свой Личный кабинет одним из следующих способов в зависимости от платформы:
                        <br/>2.13.1. Нажать на соответствующую кнопку в разделе «Профиль» в Мобильном приложении (на платформе iOS);
                        <br/>2.13.2. Обратиться в Чат или по Телефону с соответствующей просьбой (на платформе Android, при использовании Сайта).
                        <br/>3. Порядок оформления заказа
                        <br/>В Мобильном приложении
                        <br/>3.1. Клиент указывает способ получения заказа в разделе «Меню»:
                        <br/>3.1.1. При выборе «На доставку» Клиент указывает адрес доставки заказа;
                        <br/>3.1.2. При выборе «В пиццерии» Клиент указывает адрес пиццерии, в которой будет получать заказ.
                        <br/>3.2. Далее Клиент выбирает Товары, доступные для заказа у Продавца, в необходимом ассортименте и количестве из раздела «Меню» путём добавления Товара в раздел «Корзина».
                        <br/>3.3. При наличии технической возможности Клиент может изменять состава Товара в разделе «Корзина» в Мобильном приложении. Для этого Клиент нажимает кнопку «Изменить» напротив Товара, состав которого необходимо изменить, в разделе «Корзина» и выполняет соответствующее действие:
                        <br/>3.3.1. добавляет ингредиенты в состав Товара: отмечает необходимые ингредиенты в разделе «Добавить по вкусу» и нажимает кнопку «Готово»;
                        <br/>3.3.2. удаляет ингредиенты из состава Товара: нажимает кнопку «Убрать ингредиенты», отмечает ингредиенты, которые необходимо удалить, нажимает кнопку «Сохранить», затем нажимает кнопку «Готово».
                        <br/>3.4. Клиент может применить акцию в разделе «Корзина», если заказ соответствует условиям выбранной акции. Для этого Клиент выполняет одно из указанных действий:
                        <br/>3.4.1. Выбирает доступную акцию в разделе «Мои акции» и нажимает кнопку «Применить»;
                        <br/>3.4.2. Вводит промокод акции вручную, нажав на кнопку «Ввести промокод».
                        <br/>3.5. Если заказ не соответствует условиям применённой акции и акция не сработала, Клиент увидит в разделе «Корзина» сообщение «Не все условия акции выполнены».
                        <br/>3.6. Для завершения оформления заказа Товаров Клиент нажимает в разделе «Корзина» кнопку «Оформить заказ», указывает адрес доставки или Пиццерии, выбирает способ оплаты заказа и повторно нажимает кнопку «Оформить заказ». Если оплата производится банковской картой, Клиент совершает действия, предусмотренные пунктом 5.1.1. Оферты.
                        <br/>Сайт
                        <br/>3.7. Клиент выбирает Товары в необходимом ассортименте и количестве путём добавления Товара в раздел «Корзина».
                        <br/>3.7.1. Если Товары, выбранные Клиентом, отсутствуют в Пиццерии, Клиент увидит соответствующее предупреждение после указания адреса доставки заказа или адреса пиццерии, в которой Клиент будет получать заказ.
                        <br/>3.8. При наличии технической возможности Клиент может изменять состава Товара на Сайте перед добавлением в раздел «Корзина». Для этого Клиент нажимает на изображение Товара и выполняет соответствующее действие:
                        <br/>3.8.1. добавляет ингредиенты в состав Товара: отмечает необходимые ингредиенты в разделе «Добавить по вкусу» и нажимает кнопку «Добавить в корзину»;
                        <br/>3.8.2. удаляет ингредиенты из состава Товара: отмечает в составе Товара ингредиенты, которые необходимо удалить и нажимает кнопку «Добавить в корзину».
                        <br/>3.9. Клиент может применить акцию, если заказ соответствует условиям выбранной акции. Для этого Клиент выполняет одно из указанных действий:
                        <br/>3.9.1. Выбирает доступную акцию в Личном кабинете разделе «Персональные акции» и нажимает кнопку «Применить»;
                        <br/>3.9.2. Вводит промокод акции вручную, нажав на кнопку «Ввести промокод» в разделе «Корзина».
                        <br/>3.10. Если заказ не соответствует условиям применённой акции и акция не сработала, Клиент увидит в разделе «Корзина» соответствующее сообщение.
                        <br/>3.11.Для завершения оформления заказа Товаров Клиент нажимает в разделе «Корзина» кнопку «К оформлению заказа», указывает адрес доставки или Пиццерии, выбирает способ оплаты заказа и повторно нажимает кнопку «Оформить заказ». Если оплата производится банковской картой, Клиент совершает действия, предусмотренные пунктом 5.1.1. Оферты.
                        <br/>Киоск
                        <br/>3.12. Клиент выбирает Товары, доступные для заказа, в необходимом ассортименте и количестве из раздела «Меню» путём добавления Товара в раздел «Корзина».
                        <br/>3.13. При наличии технической возможности Клиент может изменять состава Товара в разделе «Корзина». Для этого Клиент нажимает кнопку «Изменить» в разделе «Корзина» и выполняет соответствующее действие:
                        <br/>3.13.1. добавляет ингредиенты в состав Товара: отмечает необходимые ингредиенты в разделе «Добавить по вкусу» и нажимает кнопку «Готово»;
                        <br/>3.13.2. удаляет ингредиенты из состава Товара: нажимает кнопку «Убрать ингредиенты», отмечает ингредиенты, которые необходимо удалить, нажимает кнопку «Сохранить», затем нажимает кнопку «Готово».
                        <br/>3.14. При заказе через Киоск Клиенту недоступно применение промокодов.
                        <br/>3.15. Для завершения оформления заказа Товаров Клиент нажимает в разделе «Корзина» кнопку «Оформить заказ», указывает способ получения заказа и повторно нажимает кнопку «Оформить заказ». Если оплата производится банковской картой, Клиент совершает действия, предусмотренные пунктом 5.1.5. Оферты.
                        <br/>4. Условия использования и ответственность Клиента
                        <br/>4.1. Клиент ответственен за правильность, полноту и достоверность указания своих данных в Личном кабинете. В случае некорректного ввода своих данных Клиент отвечает за последствия, которые могут возникнуть.
                        <br/>4.2. При регистрации запрещено использовать номера телефонов, зарегистрированные на третьих лиц, а также «виртуальные» номера телефонов. Клиент самостоятельно несёт ответственность за возможные последствия, связанные с использованием таких номеров телефонов.
                        <br/>4.3. Клиент обязуется использовать Сайт, Мобильное приложение только в законных целях и способами, не нарушающими права третьих лиц. Клиент самостоятельно несет ответственность за свои действия перед третьими лицами и(или) за соблюдение законодательства РФ при использовании Сайта, Мобильного приложения.
                        <br/>4.4. Клиент не вправе передавать данные своего Личного кабинета третьим лицам. В случае передачи Клиентом своего логина и (или) пароля третьему лицу, Клиент несет ответственность за действия третьего лица, как за свои собственные. Все действия, совершенные с использованием Личного кабинета Клиента, считаются совершенными Клиентом.
                        <br/>4.5. Клиент обязан уведомить Компанию:
                        <br/>4.5.1. В случае несанкционированного доступа третьих лиц к Личному кабинету Клиента;
                        <br/>4.5.2. О любом случае нарушения безопасности своего логина и пароля (утеря, передача данных третьим лицам и другое).
                        <br/>5. Рекомендации на Сайте и в Мобильном приложении
                        <br/>Предоставление Временных предложений Клиентам
                        <br/>5.1. Компания собирает и анализирует данные о Клиентах и их действия на Сайте и/или в Мобильном приложении: количество, состав, время и место заказов; выданные Клиентам и примененные ими персональные акции, промокоды; количество заходов в карточку Товара; количество добавлений Товара в корзину; количество имеющихся у Клиента Додокоинов и как он их тратит; количество сессий в Мобильном приложении и их продолжительность.
                        <br/>5.2. Компания собирает и анализирует сведения, указанные в п. 5.1 Соглашения, за последние 6 месяцев только о Клиентах, которые имеют Личный кабинет, хотя бы 1 раз сделали заказ в любой Пиццерии и хотя бы 1 раз использовали Мобильное приложение.
                        <br/>5.3. На основе проанализированных сведений, указанных в п. 5.1 Соглашения, Компания ежедневно по своему расписанию предоставляет каждому Клиенту, авторизованному в Мобильном приложении, N-ное количество Временных предложений Товаров для заказа на доставку и N-ное количество Временных предложений Товаров для заказа в Пиццерии и на самовывоз. Временные предложения для заказа Товаров на доставку и в Пиццерии и на самовывоз могут повторяться. Сколько Временных предложений предложить Клиенту (N), Компания определяет по своему усмотрению. Компания определяет конкретные Временные предложения для Клиента с помощью математических методов и алгоритмов исходя из вероятности, что Клиент воспользуется Временным предложением, и маржинальности Товара, предлагаемого в рамках Временного предложения.
                        <br/>5.4. Компания вправе не предоставлять Временные предложения, если в Пиццериях зафиксировано большое количество заказов.
                        <br/>Предоставление персональных акций Клиентам
                        <br/>5.5. Компания собирает и анализирует данные о Клиентах и их действиях на Сайте и/или в Мобильном приложении: наличие и/или количество заказов, давность последнего заказа, давал ли Клиент согласие на получение рекламных рассылок, установлено ли у Клиента Мобильное приложение и использует ли он его, средняя стоимость заказов Клиента за все время оформления заказов, в каком канале чаще Клиент заказывает (на доставку или в Пиццерии), как Клиент использует Додокоины и их текущее количество.
                        <br/>5.6. Компания с помощью математических методов и алгоритмов предоставляет персональные акции Клиентам:
                        <br/>5.6.1. на основе проанализированных данных о количестве Додокоинов у Клиента и о том, как он их тратит, Компания случайным образом предоставляет персональные акции из определенного Компанией перечня;
                        <br/>5.6.2. если Клиент не использует Додокоины последние 60 календарных дней, то Компания определяет, когда был последний заказ у Клиента и в каком канале (на доставку или в Пиццерии), и на основе этого случайным образом предоставляет персональные акции из определенного Компанией перечня.
                        <br/>Виджет дополнительных Товаров в карточках Товаров из раздела “Комбо”
                        <br/>5.7. Компания один раз проанализировала Товары, которые Клиенты чаще всего заказывают вместе с Товарами из раздела “Комбо” и с помощью математических методов определила перечень таких популярных сопутствующих Товаров. Один Товар из раздела “Комбо” – перечень популярных сопутствующих Товаров.
                        <br/>5.8. На основе проведенного анализа Компания показывает Клиентам при добавлении в корзину Товаров из раздела “Комбо” дополнительные Товары, которые чаще всего заказывают другие Клиенты с этим Товаром из раздела “Комбо”.
                        <br/>Блоки “Часто заказывают” и “Вам понравится”
                        <br/>5.9. С помощью математических методов, методов user-to-user и item-to-item Компания определяет Товары, которые Клиенты заказывают наиболее часто, и добавляет их в блок “Часто заказывают” на Сайте.
                        <br/>5.10. Компания с помощью математических методов анализирует состав заказов Клиентов, и если Клиент заказал более двух раз один и тот же Товар, то добавляет его в блок “Вам понравится” во вкладке “в зале” в Мобильном приложении.
                        <br/>6. Полномочия Компании
                        <br/>6.1. Компания вправе вносить изменения в Cоглашение в одностороннем порядке. Клиент обязан самостоятельно отслеживать изменения в Соглашении.
                        <br/>6.2. Компания вправе изменять Сайт, Мобильное приложение (изменять или добавлять в его структуру разделы, менять дизайн и совершать прочие действия для улучшения Сервисов Сайта, Мобильного приложения).
                        <br/>6.3. В случае нарушения Клиентом условий Соглашения, компания вправе:
                        <br/>6.3.1. Приостановить доступ Клиента к использованию Сайта, Мобильного приложения и их Сервисов;
                        <br/>6.3.2. Отказать Клиенту в использовании отдельных Сервисов Сайта, Мобильного приложения;
                        <br/>6.3.3. Удалить Личный кабинет Клиента.
                        <br/>6.4. Компания не несёт ответственность за вред, причиненный технике Клиента при использовании Сайта и Мобильного приложения, при переходе по ссылкам, размещенным на Сайте, в Мобильном приложении.
                        <br/>6.5. Компания не несет ответственность за несоответствие рекомендаций (раздел 5 Соглашения) действительным предпочтениям Клиента.
                        <br/>7. Исключительные права на содержание Сервисов и Контент
                        <br/>7.1. Весь Контент, размещенный на Сайте и в Мобильном приложении, является объектом исключительных прав Компании.
                        <br/>7.2. Никакие элементы Контента Сайта, Мобильного приложения не могут быть использованы без предварительного разрешения Компании. Под использованием подразумеваются: воспроизведение, копирование, переработка, распространение на любой основе и прочее. Исключение составляют случаи, прямо предусмотренные законодательством РФ или условиями использования того или иного Сервиса Сайта, Мобильного приложения.
                        <br/>7.3. Клиент вправе использовать Контент для личных некоммерческих нужд при условии:
                        <br/>7.3.1. Сохранения всех знаков охраны авторского права, смежных прав, товарных знаков и других уведомлений об авторстве;
                        <br/>7.3.2. Сохранения имени (в т.ч. псевдонима) автора и (или) наименования Компании в неизменном виде;
                        <br/>7.3.3. Сохранения объекта в неизменном виде. Исключение составляют случаи, прямо предусмотренные законодательством РФ.
                        <br/>8. Рассмотрение претензий правообладателей
                        <br/>8.1. Если содержимое Сайта и/или Мобильного приложения нарушает личные неимущественные/исключительные права Клиента, то Клиенту следует уведомить Компанию об этом, написав на адрес электронной почты gm.group@internet.ru.
                        <br/>8.2. Уведомление о нарушении личных неимущественных/исключительных прав должно содержать:
                        <br/>8.2.1. Указание на материалы с Сайта и/или Мобильного приложения, предположительно нарушающие права Клиента;
                        <br/>8.2.2. Сведения о материалах с приложением документов, подтверждающих права Клиента на такие материалы.
                        <br/>8.2.3. Контактную информацию о Клиенте, включая:
                        <br/>8.2.3.1. Для физического лица — фамилию, имя, отчество, паспортные данные (серия и номер, кем выдан, дата выдачи), адрес, номер телефона, адрес электронной почты;
                        <br/>8.2.3.2. Для юридического лица — наименование, регистрационный номер, место нахождения, адрес, номер телефона, адрес электронной почты;
                        <br/>8.2.4. Подпись Клиента.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UserDocument;